import React, { useEffect, useRef } from 'react'


 const ZoomImage = ({src, srcZoom}) => {

    const imgRef = useRef();
    const paneRef = useRef();
    const inlineContainerRef = useRef();
  
    useEffect(() => {
      let Drift;
      if (typeof window !== "undefined") {
        Drift = require("drift-zoom").default;
      }
      new Drift(imgRef.current, {
        paneContainer: paneRef.current,
        inlineContainer: inlineContainerRef.current
      });
    }, []);
  
    return (
      <div class="zoom-image">
        <img
          class="zoom-image__img"
          ref={imgRef}
          src={src}
          data-zoom={srcZoom}
          alt="Zoom here :)"
        />
        <div ref={inlineContainerRef} />
        <div ref={paneRef} />
      </div>
    );
}

export default ZoomImage;